import { CreditEntity } from '../../../entities/credit.entity'
import LaboratoryEntity from '../../../entities/laboratory.entity'
import OpticEntity from '../../../entities/optic.entity'
import SoftwarePartnerEntity from '../../../entities/software-partner.entity'
import store from '../../../store'

export default function useInvoicesList() {
  const tableColumns = [
    { key: 'date', label: 'date', sortable: true },
    { key: 'target', label: 'target', sortable: false },
    { key: 'transaction', label: 'transaction', sortable: false },
    { key: 'total', label: 'total', sortable: false },
    { key: 'reason', label: 'reason', sortable: false },
  ]

  /**
   * Gets the credit target name.
   *
   * @param {CreditEntity} credit the credit to be used.
   * @returns the target name.
   */
  const getTargetName = async (credit) => {
    /**
     * @type {OpticEntity | LaboratoryEntity | SoftwarePartnerEntity | null}
     */
    const target = await store.dispatch('credit/fetchTarget', credit)

    if (!target) {
      return ''
    }

    return target.nameFantasy || target.nameRegistered
  }

  return {
    tableColumns,
    getTargetName,
  }
}
