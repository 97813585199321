<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row align-h="between" align-v="center" class="mx-0">
          <div class="d-flex">
            <feather-icon icon="DollarSignIcon" size="19" />
            <h4 class="mb-0 ml-50">{{ $t('credit-history.title') }}</h4>
          </div>

          <!-- <b-button
            variant="outline-primary"
            v-ripple.400="'#04679e15'"
            :disabled="!credits || !credits.length"
          >
            <span class="align-middle">Export</span>

            <feather-icon icon="FileTextIcon" class="ml-50" />
          </b-button> -->
        </b-row>
      </div>

      <b-table
        striped
        sort-desc
        responsive
        show-empty
        id="my-table"
        sort-by="date"
        ref="refCreditsListTable"
        class="position-relative"
        :items="credits"
        :fields="
          tableColumns.map((c) => ({
            ...c,
            label: $t('credit-history.headers.' + c.label),
          }))
        "
        :empty-text="$t('admin.measurements.list.noEntries')"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />

            <strong class="ml-1">{{
              $t('admin.measurements.list.loading')
            }}</strong>
          </div>
        </template>

        <template #cell(date)="data">
          {{ fromTimestampToString(data.item.date / 1000) }}
        </template>

        <template #cell(target)="data">
          <span v-if="data.item.targetRef">
            <b-spinner
              v-if="loading.has(data.item.id)"
              variant="primary"
            ></b-spinner>

            <span v-else>
              {{ targets[data.item.targetRef] || '-' }}
            </span>
          </span>

          <b-badge v-else variant="light-primary">
            {{ $t('you') | uppercase }}
          </b-badge>
        </template>

        <template #cell(transaction)="data">
          <b-badge
            style="margin: 3px"
            :variant="getTransactionVariant(data.item.difference)"
          >
            {{ data.item.difference | transactionLabel }}
            {{ $t('measurements') | lowercase }}
          </b-badge>
        </template>

        <template #cell(total)="data">
          <b-badge :variant="data.item.remaining | totalVariant">
            {{ data.item.remaining | remaining }}
          </b-badge>
        </template>

        <template #cell(reason)="data">
          {{ $t('credit-history.reason.' + data.item.reason) }}
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BLink,
  BBadge,
  BButton,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import useCreditsList from './useCreditsList'

import store from '../../../store'

import creditStoreModule from './creditStoreModule'

import {
  beautyParserDateToString,
  fromTimestampToString,
} from '../../../utils/dates'
import { onUnmounted } from 'vue-demi'
import { CreditEntity } from '../../../entities/credit.entity'

export default {
  name: 'CreditsList',

  props: ['credits'],

  directives: {
    Ripple,
  },

  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BLink,
    BBadge,
    BButton,
    BPagination,
    BSpinner,
    BTooltip,
  },

  filters: {
    /**
     * Pipe that transforms a string to lowercase.
     *
     * @example
     * ```html
     * <span>{{ 'Abcd' | lowercase }}</span> => <span>abcd</span>
     * ```
     *
     * @param {string} value the value to be transformed.
     * @returns the lowercase value.
     */
    lowercase(value) {
      return value.toLowerCase()
    },

    /**
     * Pipe that transforms a string to uppercase.
     *
     * @example
     * ```html
     * <span>{{ 'Abcd' | uppercase }}</span> => <span>ABCD</span>
     * ```
     *
     * @param {string} value the value to be transformed.
     * @returns the uppercase value.
     */
    uppercase(value) {
      return value.toUpperCase()
    },

    /**
     * Gets the transaction label according to the given difference.
     *
     * @example
     * ```html
     * <span>{{ 200 | transationLabel }}</span> => <span>+200</span>
     * <span>{{ 0 | transationLabel }}</span> => <span>0</span>
     * <span>{{ -200 | transationLabel }}</span> => <span>-200</span>
     * ```
     *
     * @param {number} value the credit transaction difference.
     * @returns the difference label.
     */
    transactionLabel(value) {
      if (value > 0) {
        return `+${value}`
      }

      return value
    },

    /**
     * Gets the total badge variant according to the given value.
     *
     * @example
     * ```html
     * <b-badge :variant="200 | totalVariant"></b-badge>
     * => <b-badge variant="light-primary"></b-badge>
     *
     * <b-badge :variant="0 | totalVariant"></b-badge>
     * => <b-badge variant="light-warning"></b-badge>
     * ```
     *
     * @param {number} value the credit remaining credits.
     * @returns the variant name.
     */
    totalVariant(value) {
      if (value === null) {
        return 'light-info'
      }

      if (!value) {
        return 'light-warning'
      }

      return 'light-secondary'
    },

    /**
     * Gets the remaining label. In case is null, returns a `-`.
     *
     * @param {number | null} value the remaining credits.
     * @returns the label.
     */
    remaining(value) {
      return value ?? '-'
    },
  },

  data() {
    return {
      beautyParserDateToString,
      fromTimestampToString,
      targets: {},
    }
  },

  computed: {
    /**
     * Property that keeps the credit loading state, which is a Set that
     * contains all current loading credit ids.
     *
     * @usageNotes
     * To check if a credit is loading, simply use the `has` method.
     *
     * @example
     * ```js
     * this.loading.has('1234567890') => true or false
     * ```
     *
     * @type {Set<string>}
     */
    loading() {
      return this.$store.state.credit.loading
    },
  },

  methods: {
    /**
     * Gets the transaction variant color according to the given difference.
     *
     * @param {number} difference the credit transaction difference.
     * @returns the color variant.
     */
    getTransactionVariant(difference) {
      if (difference < 0) {
        return 'light-danger'
      }

      if (difference > 0) {
        return 'light-success'
      }

      return 'light-secondary'
    },

    /**
     * Sets the `targets` property according to the given credit.
     *
     * @param {CreditEntity} credit the credit to be used.
     */
    async setTarget(credit) {
      if (!credit.targetRef || this.targets[credit.targetRef]) {
        return
      }

      const name = await this.getTargetName(credit)

      if (!name) {
        return
      }

      const targets = { ...this.targets, [credit.targetRef]: name }
      this.targets = targets
    },
  },

  mounted() {
    this.credits.forEach(async (credit) => {
      await this.setTarget(credit)
    })
  },

  setup() {
    const CREDIT_APP_STORE_MODULE_NAME = 'credit'

    if (!store.hasModule(CREDIT_APP_STORE_MODULE_NAME)) {
      store.registerModule(CREDIT_APP_STORE_MODULE_NAME, creditStoreModule)
    }

    onUnmounted(
      () =>
        store.hasModule(CREDIT_APP_STORE_MODULE_NAME) &&
        store.unregisterModule(CREDIT_APP_STORE_MODULE_NAME),
    )

    const { tableColumns, getTargetName } = useCreditsList()

    return { tableColumns, getTargetName }
  },
}
</script>

<style></style>
