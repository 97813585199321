<template>
  <div>
    <b-col cols="12" lg="6" md="6" sm="12" xs="12">
      <profile-plan-card-vue />
    </b-col>
    <b-row>
      <invoices-list style="width: 100%" class="mx-1" :UserId="userData.id" />
    </b-row>

    <b-row
      v-if="
        userData &&
        userData.role !== RoleEnum.SELLER &&
        userAdminDocData &&
        userAdminDocData.creditHistory
      "
      class="mt-2"
    >
      <credits-list
        class="mx-1"
        style="width: 100%"
        :credits="userAdminDocData.creditHistory"
      />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BAlert, BLink } from 'bootstrap-vue'
import InvoicesList from '@/views/admin/invoices/InvoicesList.vue'
import CreditsList from '@/views/sections/credits/CreditsList.vue'
import RoleEnum from '../../../utils/roles'
import ProfilePlanCardVue from '@/views/sections/profile/ProfilePlanCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,

    InvoicesList,
    CreditsList,
    ProfilePlanCardVue,
  },

  data() {
    return {
      RoleEnum,
    }
  },

  computed: {
    userData() {
      return this.$store.state.auth.user
    },
    userAdminDocData() {
      return this.$store.state.auth.userAdminDoc
    },
    isPlanParent() {
      if (this.userData.adminDocRef) {
        if (this.userData.adminDocRef.split('/').length > 2) return false
        else return true
      } else {
        return false
      }
    },
  },
}
</script>

<style></style>
